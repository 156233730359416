import { ClassicButtonProps } from "@/types/components/buttons/classicButton";
import { Link } from "@inertiajs/react";
import { InertiaLinkProps } from "@inertiajs/react";

const ClassicButton = ({
    className = "",
    disabled,
    href,
    title,
    children,
    full,
    type = "button",
    linkProps,
    ...props
}: ClassicButtonProps) => {
    const btnClass =
        `inline-flex items-center px-4 py-3 bg-i-blue-to-indigo rounded-sm font-bold text-16 leading-6 font-bold text-i-light-gray text-center justify-center transition-all ease-in-out duration-300 gap-2 hover:opacity-80 ${
            full && "w-full"
        } ${disabled && "opacity-25 cursor-not-allowed"} ` + className;
    
    if (linkProps) {
        return (
            <Link {...linkProps}>
                <button className={btnClass} disabled={disabled} type={type}>
                    {children}
                </button>
            </Link>
        );
    } else if (href) {
        return (
            <a
                href={href}
                title={title}
                className={`inline-block ${full && "w-full"}`}
            >
                <button
                    {...props}
                    className={btnClass}
                    disabled={disabled}
                    type={type}
                >
                    {children}
                </button>
            </a>
        );
    }

    return (
        <button {...props} className={btnClass} disabled={disabled} type={type} title={title}>
            {children}
        </button>
    );
};

export default ClassicButton;
